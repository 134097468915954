<template>
  <div class="overall">
    <!-- 步骤条盒子 -->
    <div class="steps-box">
      <!-- 步骤条 -->
      <div
        class="Article-steps"
        :class="stepList.length <= activeIndex ? 'step-over' : ''"
      >
        <!-- 步骤条背景进度条 -->
        <div class="line">
          <span
            class="plan"
            :style="
              `width:${activeIndex * (100 / (stepList.length - 1)) -
                100 / (stepList.length - 1) / 2}%`
            "
          ></span>
        </div>
        <!-- 每步部分 -->
        <span
          class="step"
          v-for="(item, index) in stepList"
          :key="index"
          :class="
            activeIndex == item.stepIndex || item.stepIndex <= activeIndex
              ? 'step-active'
              : ''
          "
        >
          <div class="step-icon">
            <span class="step-num">
              <!-- {{i.stepIndex}}  -->
              <span v-if="item.stepIndex === 1" class="num"
                ><i class="el-icon-sell"></i
              ></span>
              <span
                v-if="item.stepIndex === 2 && item.logType !== 4"
                class="num"
                ><i class="el-icon-bank-card"></i
              ></span>

              <!-- 取消 -->
              <span
                v-if="item.stepIndex === 2 && item.logType === 4"
                class="num"
                ><i class="el-icon-s-release"></i
              ></span>
              <span v-if="item.stepIndex === 3" class="num"
                ><i class="el-icon-s-order"></i
              ></span>
              <span v-if="item.stepIndex === 4" class="num"
                ><i class="el-icon-money"></i
              ></span>
              <span v-if="item.stepIndex === 5" class="num"
                ><i class="el-icon-finished"></i
              ></span>
            </span>
          </div>

          <p class="title">{{ item.title }}</p>
          <p class="msg">{{ item.msg }}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //当前位置
      activeIndex: 1,
      //步骤条步数
      stepList: [
        {
          stepIndex: 1,
          title: "提交订单",
          msg: "无操作",
          logType: "",
        },
        {
          stepIndex: 2,
          title: "支付订单",
          msg: "无操作",
          logType: "",
        },
        {
          stepIndex: 3,
          title: "商家发货",
          msg: "无操作",
          logType: "",
        },
        {
          stepIndex: 4,
          title: "确认收货",
          msg: "无操作",
          logType: "",
        },
        {
          stepIndex: 5,
          title: "订单完成",
          msg: "无操作",
          logType: "",//操作类型1创建2支付3发货4取消5修改6完成7申请退款8申请退货9退货受理10退款完成11退货完成12退款拒绝13退货发出14确认收货
        },
      ],
    };
  },
  props: {
    timeList: {
      type: Array,
    },
  },
  methods: {},
  created() {
    // 已取消
      if (this.timeList.length === 2 && this.timeList[1].logType === 4) {
        this.stepList = [];
        this.timeList.forEach((item, i) => {
          let listdata = "";
          if (i == 0) {
            listdata = {
              stepIndex: i + 1,
              title: "提交订单",
              msg: item.createTime,
              logType: item.logType,
            };
          } else {
            listdata = {
              stepIndex: i + 1,
              title: "取消订单",
              msg: item.createTime,
              logType: item.logType,
            };
          }
          this.activeIndex = 2;
          this.stepList.push(listdata);
        });
      }else{
        if(!this.timeList[1]){
          this.timeList.forEach((item, i) => {
            this.stepList[i].stepIndex = i + 1;
            this.stepList[i].msg = this.timeList[i].createTime;
            this.stepList[i].logType = this.timeList[i].logType;
          });
          this.activeIndex = this.timeList.length;
        }else{
          if (this.timeList[1].logType !== 4) {
            this.timeList.forEach((item, i) => {
              this.stepList[i].stepIndex = i + 1;
              this.stepList[i].msg = this.timeList[i].createTime;
              this.stepList[i].logType = this.timeList[i].logType;
            });
            this.activeIndex = this.timeList.length;
          }
        }
      }
  },
};
</script>

<style lang="scss" scoped>
.overall {
  width: 90%;
}
.steps-box {
  user-select: none;
  width: 100%;
  height: 100%;
  position: relative;

  // <!-- 步骤条背景进度条 -->
  .line {
    display: block;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    top: 28px;
    left: 6%;
    background: #cccccc;
    width: 88%;
    height: 2px;
    overflow: hidden;
    .plan {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      background: #fa6400;
    }
  }
  .Article-steps {
    display: flex;
    justify-content: space-between;
    .step {
      z-index: 1;
      &:last-child {
        float: right;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-top: 5px;
        text-align: center;
      }
      .msg {
        font-size: 12px;
        font-weight: bold;
        color: #333;
        margin-top: 5px;
        text-align: center;
      }
      .step-icon {
        width: 100%;
        height: 60px;
        .step-num {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: block;
          margin: 0 auto;
          line-height: 60px;
          text-align: center;
          background: #f0f0f0;
          color: #ccc;
          font-weight: bold;
          transition: 0.5s;
          font-size: 20px;
          i {
            line-height: 60px;
            text-align: center;
            font-size: 28px;
          }
        }
      }
    }
  }

  //当前所在位置样式
  .step-active {
    .step-num {
      background: #fa6400 !important;
      color: #fff !important;
    }
    .title {
      color: #fa6400 !important;
    }
    .msg {
      color: #fa6400 !important;
    }
  }

  //全部完成样式
  .step-over {
    .plan {
      background: #fa6400 !important;
    }
    .step-num {
      background: #fa6400 !important;
    }
    .title {
      color: #fa6400 !important;
    }
    .msg {
      color: #fa6400 !important;
    }
  }
}
</style>
